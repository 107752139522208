<script setup lang="ts">
import { Constants, BaseButton, IconArrow } from "@makeitcheaper/rpp-frontend";
import IconLoading from "@/assets/icons/IconLoading.vue";
import { computed } from "vue";
const { TERTIARY, MD } = Constants;

const emit = defineEmits(["click", "back"]);

function handleNextClick() {
  emit("click");
}
function handleBackClick() {
  emit("back");
}

const props = defineProps({
  displayBack: {
    type: Boolean,
    default: true,
  },
  displayNext: {
    type: Boolean,
    default: true,
  },
  backIsDisabled: {
    type: Boolean,
    default: false,
  },
  nextIsDisabled: {
    type: Boolean,
    default: false,
  },
  nextText: {
    type: String,
    default: "Next",
  },
  nextTheme: {
    type: String,
    default: "primary",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const isLoadingIcon = computed(() => {
  if (props.isLoading) {
    return IconLoading;
  }

  return null;
});
</script>

<template>
  <Transition name="slide-up">
    <div class="Navigation" v-if="displayBack || displayNext">
      <BaseButton
        v-if="displayBack"
        :theme="TERTIARY"
        :size="MD"
        :icon-component="IconArrow"
        :disabled="backIsDisabled"
        icon-reversed
        icon-color="--black--20"
        class="Navigation__BackButton"
        @click="handleBackClick"
      />

      <BaseButton
        :theme="nextTheme"
        v-if="displayNext"
        :disabled="nextIsDisabled"
        :icon-component="isLoadingIcon"
        class="Navigation__BaseButton"
        @click="handleNextClick"
      >
        <template v-if="!isLoading">
          {{ props.nextText }}
        </template>
      </BaseButton>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.Navigation {
  margin-top: var(--spacing--40);
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing--20);

  &__BaseButton {
    @include fontSize(16px);
    line-height: var(--spacing--16);
  }

  .Button + .Button {
    margin-left: var(--spacing--24);

    @media (min-width: $mq--sm) {
      margin-left: var(--spacing--40);
    }
  }

  &__BackButton {
    border-color: var(--black--20);
    padding: var(--spacing--16);
  }

  .Button {
    min-height: var(--spacing--48);
  }
}

[partner="bionic"] {
}
[partner="msm"] {
  .Navigation__BaseButton {
  }
}
</style>
