<script setup lang="ts">
import { computed } from "vue";
import { ErrorMessage } from "@makeitcheaper/rpp-frontend";
import DataCaptureNavigation from "@/components/UI/molecules/DataCaptureNavigation.vue";
import {
  useAnalyticsClient,
  PLATFORM_JOURNEY,
  EVENT_BUTTON,
} from "@/composables/useAnalyticsClient";
import { TRACKING_BACK } from "@/helpers/constants";

const analyticsClient = useAnalyticsClient();

const props = defineProps({
  heading: {
    type: [String, Array<string>],
    default: "",
  },
  subheading: {
    type: [String, Array<string>],
    default: "",
  },
  nextText: {
    type: String,
    default: "Next",
  },
  nextIsDisabled: {
    type: Boolean,
    default: false,
  },
  nextTheme: {
    type: String,
    default: "primary",
  },
  backIsDisabled: {
    type: Boolean,
    default: false,
  },
  displayBack: {
    type: Boolean,
    default: true,
  },
  displayNext: {
    type: Boolean,
    default: true,
  },
  useAnims: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: "",
  },
});

const headings = computed(() =>
  Array.isArray(props.heading) ? props.heading : [props.heading],
);

const subheadings = computed(() =>
  Array.isArray(props.subheading) ? props.subheading : [props.subheading],
);

const emit = defineEmits(["click", "back"]);

function handleNextClick() {
  emit("click");
}

function handleBackClick() {
  emit("back");
}
</script>

<template>
  <div class="dataCapturePage">
    <div v-if="heading.length" class="dataCapturePage__heading">
      <template v-for="(item, idx) in headings">
        <Transition
          :name="props.useAnims ? (idx > 0 ? 'fade' : 'slide-up--lg') : ''"
          appear
        >
          <h1 :key="idx" class="LargeTitle" v-html="item" />
        </Transition>
      </template>
    </div>

    <div v-if="subheading.length" class="dataCapturePage__subheading">
      <template v-for="(item, idx) in subheadings">
        <Transition :name="props.useAnims ? 'fade' : ''" appear>
          <h1 :key="idx" class="MainTitle">
            {{ item }}
          </h1>
        </Transition>
      </template>
    </div>

    <div v-if="$slots.default" class="dataCapturePage__content">
      <Transition :name="props.useAnims ? 'slide-up' : ''" appear>
        <slot></slot>
      </Transition>

      <ErrorMessage
        v-if="errorMessage.length"
        class="dataCapturePage__error"
        :error-message="errorMessage"
      />
    </div>

    <DataCaptureNavigation
      v-bind="props"
      @click="handleNextClick"
      @back="handleBackClick"
    />
  </div>
</template>

<style scoped lang="scss">
.dataCapturePage {
  &__heading,
  &__subheading,
  &__content {
    text-align: center;
    margin-bottom: var(--spacing--24);
    @media (min-width: $mq--sm) {
      margin-bottom: var(--spacing--40);
    }
  }

  &__subheading h1 {
    font-weight: 400;
  }

  .dataCapturePage__error {
    margin-top: var(--spacing--6);
  }
}
</style>
