<script setup lang="ts"></script>
<template>
  <div class="icon">
    <img class="is-loading" src="../loading-icon.png" alt="" />
  </div>
</template>
<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  $icon-size: 1.25rem;

  max-width: $icon-size;
  max-height: $icon-size;

  min-height: 16px;

  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  img {
    max-width: $icon-size;
    max-height: $icon-size;

    &.is-loading {
      /* The animation part: */
      animation-name: spin;
      animation-duration: 0.66s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}
</style>
